// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bW_kw";
export var caseStudyBackground__lineColor = "bW_kt";
export var caseStudyHead = "bW_kn";
export var caseStudyHead__imageWrapper = "bW_kp";
export var caseStudyProjectsSection = "bW_kx";
export var caseStudyQuote__bgLight = "bW_kD";
export var caseStudyQuote__bgRing = "bW_kr";
export var caseStudyVideoReview = "bW_l7";
export var caseStudyVideoReview__bgRing = "bW_l8";
export var caseStudyVideo__ring = "bW_kG";
export var caseStudy__bgDark = "bW_km";
export var caseStudy__bgLight = "bW_kl";
export var caseStudy__bgLightReverse = "bW_l6";